.main-body{
  padding-top: 64px;
}

.video-full-page {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1000;
  opacity: 1;
  -webkit-transition: opacity ease-out 0.65s;
  transition: opacity ease-out 0.65s;
}
.video-full-page > .video-player-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.video-full-page > .video-player-box > .video-skip-button {
  position: absolute;
  top: 1rem;
  right: 3rem;
  padding: 8px 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
}
.video-full-page > .video-player-box > .video-skip-button:hover {
  border-color: #309E48;
  color: #309E48;
}
.video-full-page > .video-player-box > .video-tech {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  object-fit: cover;
}
.bg-first-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1000px;
}
.bg-first-container .bg-first-content-box {
  display: inline-block;
  width: 600px;
  margin-top: 180px;
  margin-left: 20vw;
}
.bg-first-container .bg-first-content-box .bg-first-title {
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  background: #38f9d7;
  background: -webkit-gradient(linear, left top, left bottom, from(#43e97b), to(#38f9d7));
  background: linear-gradient(180deg, #43e97b 0%, #38f9d7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.bg-first-container .bg-first-content-box .bg-first-introduce {
  font-size: 55px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
}
.bg-first-container .bg-first-content-box .bg-first-button {
  line-height: 1.5;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  border-color: #d9d9d9;
  background: transparent;
  outline: none;
}
.bg-first-container .bg-first-content-box .bg-first-button:hover {
  color: #00A651;
  border-color: #00A651;
}
.bg-second-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1000px;
}
.bg-second-container .content-box {
  display: inline-block;
  width: 600px;
  margin-top: 180px;
  margin-left: 40vw;
}
.bg-second-container .content-box .introduce {
  font-size: 55px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
}
.bg-second-container .content-box .button {
  line-height: 1.5;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  border-color: #d9d9d9;
  background: transparent;
  outline: none;
}
.bg-second-container .content-box .button:hover {
  color: #00A651;
  border-color: #00A651;
}
.bg-third-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1000px;
}
.bg-third-container .bg-third-content-box {
  margin-top: 160px;
  text-align: center;
}
.bg-third-container .bg-third-content-box .bg-third-title {
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  -webkit-background-clip: text;
          background-clip: text;
  color: #fff;
}
.bg-third-container .bg-third-content-box .bg-third-bottom {
  font-family: Microsoft YaHei;
  -webkit-background-clip: text;
          background-clip: text;
  color: #fff;
  margin-top: 30%;
}
.bg-third-container .bg-third-content-box .bg-third-button {
  line-height: 1.5;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  border-color: #d9d9d9;
  background: transparent;
  outline: none;
}
.bg-third-container .bg-third-content-box .bg-third-button:hover {
  color: #00A651;
  border-color: #00A651;
}
.banner-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.banner-box .banner-anim-elem {
  height: 100%;
}
.indexBottomStyle {
  font-family: Microsoft YaHei;
  -webkit-background-clip: text;
          background-clip: text;
  color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 1000;
}
.indexBottomColor {
  color: #fff;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  right: 7px;
}
.ql-container.ql-snow {
  border: none !important;
}
.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
  display: none;
}
.vjs-tech {
  object-fit: cover;
}
.vjs-poster {
  background-size: cover;
}
.video-js .vjs-big-play-button {
  display: none;
}
.footer-wrapper {
  background-color: #242424;
  overflow: hidden;
}
.footer-wrapper .footer {
  height: 100%;
  color: #999;
  position: relative;
}
.footer-wrapper .footer .footer-content {
  padding: 16px 0;
  line-height: 40px;
  display: flex;
  justify-content: center;
}
.footer-wrapper .footer .footer-content > section {
  padding: 0 50px;
}
.footer-wrapper .footer .footer-content > section:first-child {
  padding-left: 0;
}
.footer-wrapper .footer .footer-content > section:last-child {
  padding-right: 0;
}
.footer-wrapper .footer .footer-content .footer-icon {
  width: 14px;
  margin-right: 12px;
}
.footer-wrapper .footer .footer-content .footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-wrapper .footer .footer-bar {
  background: #1b1b1b;
  line-height: 48px;
  text-align: center;
}
.footer-wrapper .footer .footer-link {
  color: rgba(255, 255, 255, 0.4);
}
.footer-wrapper .footer .footer-link:hover {
  cursor: pointer;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .footer-wrapper .footer {
    font-size: 12px;
  }
  .footer-wrapper .footer.home-page {
    padding: 0;
  }
  .footer-wrapper .footer > div {
    width: 90%;
    margin: auto;
  }
}
.header {
  width: 100%;
  min-width: 1200px;
  z-index: 9;
  position: fixed;
  top: 0;
}
.header .home-page {
  display: flex;
  padding: 0 1.4vw 0 3vw;
}
.header-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  top: 12px;
  min-width: 320px;
}
.header-logo::after {
  content: "";
  border: 0.5px solid rgba(51, 51, 51, 0.4);
  margin-left: -190px;
  border-radius: 0.5px;
  height: 30px;
}
.header-logo .header-logo-icon {
  height: 100%;
  width: auto;
}
.header-logo .header-logo-text {
  height: 24px;
  width: auto;
  margin-left: 30px;
}
.header-logo a {
  display: block;
}
.header-menu {
  flex: 1 1;
}
.header-menu > .ant-menu {
  text-align: right;
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border: none;
  position: relative;
}
.header-menu > .ant-menu > .ant-menu-item {
  border: none;
  padding: 0 1.4vw;
}
.header-icon {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.header-icon li {
  margin-left: 8px;
  display: inline-block;
}
.header-icon li:hover {
  cursor: pointer;
}
.header .qr-code {
  position: absolute;
  top: 64px;
  right: 12px;
  width: 128px;
  height: 128px;
  display: none;
}
.header-bg-white {
  background: #fff;
}
.header-bg-white .ant-menu-item a {
  color: #333333;
}
.header-bg-white .ant-menu-item a:hover {
  color: #309E48;
}
.header-bg-white .ant-menu-item-selected a {
  color: #309E48;
}
.header-bg-transparent {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}
.header-bg-transparent .header-logo-text {
  display: none;
}
.header-bg-transparent .header-logo::after {
  content: none;
}
.header-bg-transparent .ant-menu-item a {
  color: #fff;
}
.header-bg-transparent .ant-menu-item a:hover {
  color: #fff;
  font-weight: 700;
}
.header-bg-transparent .ant-menu-item-selected a {
  color: #fff;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .header-logo {
    z-index: 101;
  }
  .header.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header .header-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header .header-menu li {
    padding: 0 24px;
  }
  .header .header-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header .header-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-mobile-menu :first-child {
    margin-top: 0;
  }
  .header .ant-menu {
    height: auto;
    overflow: hidden;
    background: #242424;
  }
  .header .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header .ant-menu a {
    color: #fff;
  }
  .header .ant-menu a:hover {
    color: #fff;
  }
  .header .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header .open {
    height: auto;
  }
  .header .open .header-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header .open .header-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header .open .header-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header .open > .header-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.toolbar-wrapper {
  position: fixed;
  bottom: 80px;
  right: 40px;
  display: flex;
  flex-direction: column;
}
.toolbar-wrapper > div {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.toolbar-wrapper > div:hover {
  cursor: pointer;
  background: #e8e8e8;
}
.toolbar-wrapper > div .toolbar-item {
  padding: 12px;
  height: 100%;
  line-height: 100%;
}
.toolbar-wrapper > div .toolbar-item > img {
  max-width: 100%;
  max-height: 100%;
}
.toolbar-wrapper > div.toolbar-top {
  background: #22973b;
  border: 1px solid #22973b;
}
.sidebar-swipper .sidebar-button-hide {
  margin: -18px;
  padding: 0 18px;
  height: 46px;
  background: #00a651;
  color: #fff;
}
.sidebar-swipper .sidebar-button-hide:hover {
  cursor: pointer;
}
.sidebar-swipper .sidebar-button-hide::before {
  content: '>';
  font-size: 28px;
}
.sidebar-swipper .sidebar-bg {
  margin-top: 18px;
  padding: 18px 0;
  height: 200px;
  text-align: center;
}
.sidebar-swipper .sidebar-bg > img {
  height: 100%;
}
.sidebar-swipper .sidebar-msg-box,
.sidebar-swipper input {
  background: #e8e8e8;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  line-height: 20px;
  color: #333;
}
.sidebar-swipper .sidebar-msg-box:hover,
.sidebar-swipper input:hover,
.sidebar-swipper .sidebar-msg-box:focus,
.sidebar-swipper input:focus {
  border: none;
  box-shadow: none;
}
.sidebar-swipper .sidebar-msg-box {
  min-height: 100px;
}
.sidebar-swipper input::-webkit-input-placeholder,
.sidebar-swipper textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}
.sidebar-swipper .ant-drawer-body {
  padding: 18px;
}
.sidebar-swipper .has-error .ant-input,
.sidebar-swipper .has-error .ant-input:hover {
  background: #e8e8e8;
}
.sidebar-swipper .has-error .ant-input:focus {
  box-shadow: none;
}

